* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px/1;
  font-family: Menlo, sans-serif;
  font-variant-ligatures: common-ligatures, contextual;
  font-feature-settings: "kern", "liga", "clig", "calt";
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "alt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
}

.wrap {
  width: 95%;
  max-width: 42rem;
  margin: 0 auto;
  border: none;
}
